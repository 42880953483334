/* eslint-disable valtio/state-snapshot-rule */
import NoCallHistoryIcon from "@/assets/CallHistoryIcon.svg";
import { DropDownButton, DropDownContainer } from "@/components/keyPad.style";
import CallLogDetails from "@/components/recentCallScreen/CallLogDetails";
import { IconButtonBox } from "@/components/shared/Button";
import CallTranscriptOverlay from "@/components/shared/CallTranscriptOverlay";
import Checkbox from "@/components/shared/Checkbox";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import {
  dropdownContainer,
  headerDropdown,
  sectionHeaderDropdownItemStyle,
} from "@/components/shared/Dropdown.style";
import NoResultFound from "@/components/shared/NoResultFound";
import { filterCalls, getCallTranscript } from "@/utils/calls/callUtils";
import { deleteStoredCalls } from "@/utils/database";
import { atoms } from "@/utils/helpers/atoms";
import { cleanPhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { deleteMessages } from "@/utils/messaging/deleteMessages";
import { sendSeenFlag } from "@/utils/messaging/fetchMessages";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DialpadIcon from "@mui/icons-material/Dialpad";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAtomValue } from "jotai";
import { AnimatePresence } from "motion/react";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useEventListener, useOnClickOutside, useTimeout } from "usehooks-ts";
import { useSnapshot } from "valtio/react";
import { ListContainer } from "../../components/shared/ListItem.style";
import SearchBar from "../../components/shared/SearchBar";
import SectionHeader from "../../components/shared/SectionHeader";
import { colors } from "../../styles/global.styles";
import Call from "../../utils/calls/Call";
import { callsState } from "../../utils/calls/callState";
import {
  ConvoMenuItem,
  ConvoMenuSections,
  EditConvoMenu,
} from "../conversationsScreen/ConversationScreen.style";
import DialerScreen from "../dialerScreen/DialerScreen";
import { Box } from "../layout/Layout.style";
import RecentCallItem from "./RecentCallItem";

const CALLS_LOAD_NUM = 20;

const RecentCallScreen = () => {
  const { calls, mapVersion } = useSnapshot(callsState);
  const [selectedCall, setSelectedCall] = useState<Call | null>(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [displayedCalls, setDisplayedCalls] = useState<Call[]>([]);
  const previousCallLogsSize = useRef(calls.size);
  const callScreenRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCallLogs, setSelectedCallLogs] = useState<string[]>([]);
  const [selectedCallLogsCheckboxes, setSelectedCallLogsCheckboxes] = useState<
    string[]
  >([]);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [showCallTranscript, setShowCallTranscript] = useState(false);
  const [singleCallToDelete, setSingleCallToDelete] = useState<string | null>(
    null
  );
  const callTranscript = useAtomValue(atoms.messaging.callTranscriptText);
  const callTranscriptRemoteSender = useAtomValue(
    atoms.messaging.callTranscriptRemoteSender
  );
  const [animateDialer, setAnimateDialer] = useState(false);
  const [contactBlocked, setContactBlocked] = useState<{
    [phoneNumber: string]: boolean;
  }>({});
  useTimeout(
    () => {
      if (animateDialer) {
        setAnimateDialer(false);
      }
    },
    animateDialer ? 250 : null
  );

  function loadMoreCalls(startFromBeginning = false) {
    const startIndex = startFromBeginning ? 0 : displayedCalls.length;
    console.log(
      `Calls to load: ${callsState.calls.size}, start index: ${startIndex}`
    );

    // No more calls to load
    if (startIndex === callsState.calls.size) {
      return;
    }

    const sortedCallsbyDate = Array.from(callsState.calls.values()).sort(
      (a, b) => b.message.Date.getTime() - a.message.Date.getTime()
    );

    const nextCalls = sortedCallsbyDate.slice(
      startIndex,
      displayedCalls.length + CALLS_LOAD_NUM
    );

    // Mark unread calls as read
    for (const call of nextCalls.filter((c) => !c.isRead)) {
      void sendSeenFlag(call.message.ObjectId);
    }

    console.log(
      "Showing ",
      nextCalls.length,
      " calls from [",
      startIndex,
      "-",
      startIndex + CALLS_LOAD_NUM,
      "]"
    );
    setDisplayedCalls(
      startFromBeginning ? [...nextCalls] : [...displayedCalls, ...nextCalls]
    );
  }

  useEffect(() => {
    if (calls && calls.size > 0) {
      setLoading(false);
    }
    console.log("Received call update, refreshing");
    const sortedCallsbyDate = Array.from(calls.values()).sort(
      (a, b) => b.message.Date.getTime() - a.message.Date.getTime()
    );
    const nextCalls = Array.from(sortedCallsbyDate.values()).slice(
      0,
      displayedCalls.length
    );
    setDisplayedCalls([...nextCalls]);
  }, [mapVersion]);

  useEffect(() => {
    if (previousCallLogsSize.current != calls.size) {
      loadMoreCalls(true);
    }

    previousCallLogsSize.current = calls.size;
    console.log("calls changed");
  }, [mapVersion]);

  useEffect(() => {
    if (!loading) {
      loadMoreCalls();
    }
  }, [loading]);

  useEventListener("keydown", (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowCallTranscript(false);
    }
  });

  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  useOnClickOutside(dropdownRef, () => closeDropdown(), "mouseup");

  const toggleOverlay = () => {
    if (selectedCallLogs.length > 0) {
      setOverlayOpen(!overlayOpen);
    }
  };

  const handleDeleteSelectedCallLogs = async () => {
    await deleteCalls(selectedCallLogs);
    setSelectedCallLogs([]);
    setIsEditing(false);
    toggleOverlay();
  };

  const deleteCalls = async (callIds: string[]) => {
    const usedToastId = `deleteCall-${callIds.join("-")}-${Date.now()}`;

    toast.loading(
      `Deleting ${callIds.length > 1 ? "call logs" : "call log"}...`,
      {
        id: usedToastId,
      }
    );

    let result = false;

    try {
      result = await deleteMessages(callIds);

      if (result) {
        console.log(`Result deleting call(${callIds}) -> ${result}`);
        await deleteStoredCalls(callIds);

        // Show success toast
        toast.success(
          `${callIds.length > 1 ? "Call logs" : "Call log"} deleted successfully`,
          { id: usedToastId }
        );
      } else {
        toast.error(
          `Error deleting ${callIds.length > 1 ? "call logs" : "the call log"}, please try again later.`,
          { id: usedToastId }
        );
      }
    } catch (err) {
      console.error(`Error deleting calls`, callIds, err);

      toast.error(
        `Error deleting ${callIds.length > 1 ? "call logs" : "the call log"}, please try again later.`,
        { id: usedToastId }
      );
    }
  };

  const handleConfirmDeleteSingleCall = async () => {
    if (singleCallToDelete) {
      await deleteCalls([singleCallToDelete]);
      setSingleCallToDelete(null);
    }
  };

  const handleShowCallTranscript = () => {
    setShowCallTranscript(!showCallTranscript);
  };

  const handleSelectedCallLog = (callId: string, selected: boolean) => {
    setSelectedCallLogs((prevSelectedCallLogs) => {
      const isSelected = prevSelectedCallLogs.includes(callId);

      if (selected) {
        setSelectedCallLogsCheckboxes([...selectedCallLogsCheckboxes, callId]);
      } else {
        setSelectedCallLogsCheckboxes(
          selectedCallLogsCheckboxes.filter((item) => item !== callId)
        );
      }

      if (isSelected) {
        return prevSelectedCallLogs.filter(
          (selectedCallLogs) => selectedCallLogs !== callId
        );
      } else {
        return [...prevSelectedCallLogs, callId];
      }
    });
  };

  const displayedCallsMapped = useMemo(
    () =>
      displayedCalls.reduce((acc, call) => {
        const callTranscript = getCallTranscript(call);

        const handleContactBlockedItem = (blocked: boolean) => {
          handleContactBlocked(call.peer.getMainPhoneNumber(), blocked);
        };

        const recentCallItem = (
          <RecentCallItem
            key={call.callId}
            call={call}
            isEditing={isEditing}
            showCallTranscript={handleShowCallTranscript}
            hasCallTranscript={callTranscript !== undefined}
            callTranscript={callTranscript}
            onDeleteCall={setSingleCallToDelete}
            setSelectedCall={setSelectedCall}
            contactBlocked={
              contactBlocked[cleanPhoneNumber(call.peer.getMainPhoneNumber())]
            }
            onContactBlocked={handleContactBlockedItem}
            selectedCallLogsCheckboxes={selectedCallLogsCheckboxes}
            onSelectedCallLog={handleSelectedCallLog}
          />
        );

        if (searchQuery.length) {
          const filteredCalls = filterCalls(displayedCalls, searchQuery);

          if (filteredCalls.includes(call)) {
            acc.push(recentCallItem);
          }
        } else {
          acc.push(recentCallItem);
        }

        return acc;
      }, [] as ReactNode[]),
    [
      displayedCalls,
      searchQuery,
      contactBlocked,
      isEditing,
      selectedCallLogsCheckboxes,
    ]
  );
  const selectedCalls =
    searchQuery.length === 0
      ? displayedCalls.map((call) => call.callId)
      : filterCalls(displayedCalls, searchQuery.toLowerCase()).map(
          (call) => call.callId
        );

  const callIdsArray: string[] = [];
  // let callsArray = displayedCalls.entries().toArray();
  for (let i = 0; i < selectedCalls.length; i++) {
    callIdsArray.push(selectedCalls[i]);
  }

  const setAllSelectedCallLogs = () => {
    if (selectedCallLogs.length === callIdsArray.length) {
      setSelectedCallLogs([]);
    } else {
      setSelectedCallLogs(selectedCalls);
    }
    if (selectedCallLogsCheckboxes.length === callIdsArray.length) {
      setSelectedCallLogsCheckboxes([]);
    } else {
      setSelectedCallLogsCheckboxes(callIdsArray);
    }
  };

  const handleCloseEditing = () => {
    setSelectedCallLogs([]);
    setIsEditing(false);
    setSelectedCallLogsCheckboxes([]);
  };

  const confirmationMessage =
    selectedCallLogs.length === callIdsArray.length
      ? "Are you sure you want to delete all currently loaded call logs?"
      : "Are you sure you want to delete the selected call logs?";

  const handleContactBlocked = (phoneNumber: string, blocked: boolean) => {
    setContactBlocked((current) => {
      return { ...current, [cleanPhoneNumber(phoneNumber)]: blocked };
    });
  };

  const handleContactBlockedSelectedCall = (blocked: boolean) => {
    if (!selectedCall) {
      return;
    }
    handleContactBlocked(selectedCall.peer.getMainPhoneNumber(), blocked);
  };

  const handleShowDialer = () => {
    // If dialer not already opened dont run the animation
    if (!selectedCall) {
      setAnimateDialer(true);
    }
    setSelectedCall(null);
  };

  return (
    <>
      <Box ref={callScreenRef}>
        {displayedCalls.length > 0 ? (
          <>
            <SectionHeader
              pageName="Calls"
              iconButton={<DialpadIcon />}
              onClick={handleShowDialer}
            >
              {!isEditing && (
                <div css={{ position: "relative", display: "inline-block" }}>
                  <IconButtonBox ref={dropdownRef} onClick={toggleDropdown}>
                    <MoreVertIcon css={{ color: colors.primaryTextColor }} />
                    {isDropdownOpen && (
                      <DropDownContainer
                        css={[dropdownContainer, headerDropdown]}
                      >
                        <DropDownButton
                          css={sectionHeaderDropdownItemStyle}
                          onClick={() => setIsEditing(true)}
                        >
                          <EditIcon /> Edit
                        </DropDownButton>
                      </DropDownContainer>
                    )}
                  </IconButtonBox>
                </div>
              )}
            </SectionHeader>
            {!isEditing && (
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            )}

            {isEditing && (
              <EditConvoMenu>
                <ConvoMenuSections>
                  <Checkbox
                    checked={
                      selectedCallLogsCheckboxes.length === callIdsArray.length
                    }
                    partiallyChecked={selectedCallLogs.length > 0}
                    onChange={setAllSelectedCallLogs}
                  />
                  <label>{selectedCallLogs.length} selected</label>
                </ConvoMenuSections>

                <ConvoMenuSections>
                  <ConvoMenuItem
                    css={{
                      borderRight: "1px solid",
                      borderColor: colors.secondaryTextColor,
                      paddingRight: "1em",
                      color:
                        selectedCallLogs.length > 0
                          ? colors.primaryTextColor
                          : colors.secondaryTextColor,
                      cursor: "pointer",
                    }}
                    onClick={toggleOverlay}
                  >
                    <DeleteIcon />
                    Delete
                  </ConvoMenuItem>
                  <ConvoMenuItem
                    css={{
                      color: colors.primaryAccentColor,
                      cursor: "pointer",
                    }}
                    onClick={handleCloseEditing}
                  >
                    <CloseIcon />
                    Close
                  </ConvoMenuItem>
                </ConvoMenuSections>
              </EditConvoMenu>
            )}
            {displayedCallsMapped.length > 0 ? (
              <ListContainer
                onScrollEnd={() => {
                  if (!loading) {
                    loadMoreCalls();
                  }
                }}
              >
                {displayedCallsMapped}
              </ListContainer>
            ) : (
              <NoResultFound placeHolder="" searchQuery={searchQuery} />
            )}

            <AnimatePresence>
              {overlayOpen && (
                <ConfirmationPopup
                  togglePopup={toggleOverlay}
                  title="Delete Call Logs"
                  confirmationMessage={confirmationMessage}
                  handleAction={handleDeleteSelectedCallLogs}
                />
              )}
            </AnimatePresence>
          </>
        ) : (
          <>
            <SectionHeader pageName="Calls" />
            <div
              css={{
                color: colors.secondaryTextColor,
                fontSize: "1.5rem",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "2em",
                alignItems: "center",
              }}
            >
              <img src={NoCallHistoryIcon} alt="Call History" />
              <div>No Call History</div>
            </div>
          </>
        )}
      </Box>

      {selectedCall ? (
        <CallLogDetails
          key={selectedCall.peer.id}
          closeCallLogDetails={() => setSelectedCall(null)}
          contact={selectedCall.peer}
          showCallTranscript={() => setShowCallTranscript(true)}
          onDeleteCall={setSingleCallToDelete}
          contactBlocked={
            contactBlocked[
              cleanPhoneNumber(selectedCall.peer.getMainPhoneNumber())
            ]
          }
          onContactBlocked={handleContactBlockedSelectedCall}
        />
      ) : (
        <DialerScreen
          animate={animateDialer}
          onContactBlocked={handleContactBlocked}
        />
      )}

      {showCallTranscript && (
        <CallTranscriptOverlay
          togglePopup={handleShowCallTranscript}
          handleAction={handleShowCallTranscript}
          transcript={callTranscript}
          remote={callTranscriptRemoteSender}
        />
      )}

      <AnimatePresence>
        {singleCallToDelete && (
          <ConfirmationPopup
            togglePopup={() => setSingleCallToDelete(null)}
            title="Delete Call Log"
            confirmationMessage={
              <p>Are you sure you want to delete this call log?</p>
            }
            handleAction={handleConfirmDeleteSingleCall}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default RecentCallScreen;
