import { colors } from "@/styles/global.styles";
import KeyPad from "../../components/keyPad";
import { Box } from "../layout/Layout.style";

const DialerScreen = ({
  animate,
  onContactBlocked,
}: {
  animate: boolean;
  onContactBlocked: (phoneNumber: string, blocked: boolean) => void;
}) => {
  return (
    <Box
      css={{
        position: "relative",
        overflow: "visible",
        height: "100%",
        backgroundColor: animate
          ? colors.secondaryBackground
          : colors.primaryBackground,
        transition: "background-color 0.25s ease",
        "@media (max-height: 700px)": {
          paddingBottom: "2vh",
        },
      }}
    >
      <KeyPad onContactBlocked={onContactBlocked} />
    </Box>
  );
};

export default DialerScreen;
