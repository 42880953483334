import { colors } from "@/styles/global.styles";
import Call from "@/utils/calls/Call";
import { callsState } from "@/utils/calls/callState";
import { getCallTranscript } from "@/utils/calls/callUtils";
import { atoms } from "@/utils/helpers/atoms";
import { generateRandomString } from "@/utils/helpers/Utils";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { css } from "@emotion/react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { format } from "date-fns/format";
import { formatRelative } from "date-fns/formatRelative";
import { isSameDay } from "date-fns/isSameDay";
import { useSetAtom } from "jotai";
import capitalize from "lodash/capitalize";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useSnapshot } from "valtio";
import TranscriptIcon from "../../assets/transcript.svg?react";
import { DeleteDropDownContainer, DropDownButton } from "../keyPad.style";
import BodyPortal from "../shared/BodyPortal";
import { IconButton } from "../shared/Button";
import {
  deleteDropdownContainer,
  footerDropdownItem,
} from "../shared/Dropdown.style";
import { ListContainer } from "../shared/ListItem.style";
import ToolTip from "../shared/Tooltip";
import {
  callInfo,
  callTypeIconCss,
  iconFillCss,
  transcriptButtonCss,
} from "./RecentCallCard.style";

export default function CallLogItems({
  contact,
  showCallTranscript,
  onDeleteCall,
}: {
  contact: WebGwContact;
  showCallTranscript: () => void;
  onDeleteCall: (callId: string) => void;
}) {
  const { calls } = useSnapshot(callsState);

  const callList = calls
    .values()
    .toArray()
    .sort((a, b) => b.message.Date.getTime() - a.message.Date.getTime())
    .filter((call) => contact.filterContactOnPhone(call.remote))
    .map((call, idx, calls) => (
      <CallLogItem
        key={idx}
        calls={calls}
        callIdx={idx}
        call={call}
        showCallTranscript={showCallTranscript}
        onDeleteCall={onDeleteCall}
      />
    ));

  return <ListContainer>{callList}</ListContainer>;
}

function CallLogItem({
  call,
  calls,
  callIdx,
  showCallTranscript,
  onDeleteCall,
}: {
  call: Call;
  calls: Call[];
  callIdx: number;
  showCallTranscript: () => void;
  onDeleteCall: (callId: string) => void;
}) {
  const setCallTranscriptText = useSetAtom(atoms.messaging.callTranscriptText);
  const setCallTranscriptRemoteSender = useSetAtom(
    atoms.messaging.callTranscriptRemoteSender
  );

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };
  const closeDropdown = (e: Event) => {
    e?.stopPropagation();
    setDropdownOpen(false);
  };

  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 });

  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  useOnClickOutside(dropdownRef, closeDropdown, "mousedown");

  const callLog = call.callLogPayload;

  const callLength = new Date();
  callLength.setHours(0);
  callLength.setMinutes(0);
  callLength.setSeconds(
    callLog.end_call_timestamp && callLog.start_call_timestamp
      ? callLog.end_call_timestamp - callLog.start_call_timestamp
      : 0
  );

  const callStartDate = callLog.start_call_timestamp
    ? new Date(callLog.start_call_timestamp * 1000)
    : call.message.Date;
  const callStartStr = format(callStartDate, "h:mm aa");
  const callDurationStr =
    callLength.getHours() > 0
      ? format(callLength, "hh:mm:ss")
      : format(callLength, "mm:ss");

  const missedCall = callLog.type === "missed";
  const incomingCall = call.message.Direction === "In";
  const cancelledCall = callLength.getSeconds() === 0;

  const previousCall = calls.at(callIdx - 1);
  const isSameDayResult = !previousCall
    ? false
    : isSameDay(callStartDate, previousCall.message.Date);

  const headerDateStr = capitalize(
    formatRelative(callStartDate, new Date()).replace(/\sat.*/, "")
  );

  const phoneType =
    call.peer.getAllPhoneNumbersWithTypes().find(([phonenumber, _type]) => {
      return isSamePhoneNumber(phonenumber, call.remote);
    })?.[1] || "";

  const callTypeIcon =
    callLog.media === "video" ? (
      <div css={[callTypeIconCss(), { justifyContent: "center" }]}>
        <VideocamOutlinedIcon css={iconFillCss} />
      </div>
    ) : callLog.media === "audio" ? (
      <div css={[callTypeIconCss(), { justifyContent: "center" }]}>
        <PhoneOutlinedIcon css={iconFillCss} />
      </div>
    ) : null;

  const callTranscript = getCallTranscript(call);

  const key = call.message["imdn.Message-ID"] || generateRandomString(15);

  const handleRightClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    toggleDropdown();
    setPosition({ x: event.clientX, y: event.clientY });
    event.stopPropagation();
  };

  const handleDeleteCall = (
    event: React.MouseEvent<HTMLElement>,
    key: string
  ) => {
    event.stopPropagation();
    onDeleteCall(key);
    toggleDropdown();
  };

  return (
    <div
      onContextMenu={handleRightClick}
      css={{
        padding: "0 1em",
        fontWeight: "600",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "0.25em",
      }}
    >
      {(callIdx === 0 || !isSameDayResult) && (
        <div
          css={[callInfo, { fontSize: "0.85em", padding: "0 0.5em" }]}
          style={callIdx === 0 ? undefined : { paddingTop: "1.5em" }}
        >
          {headerDateStr}
        </div>
      )}
      <div
        css={{
          display: "flex",
          gap: "0.5em",
          alignItems: "center",
          height: "2.5em",
          padding: "0 0.5em",
          borderRadius: "6px",
          transition: "background-color 0.1s ease",
          ":hover": {
            backgroundColor: colors.secondaryBackground,
          },
        }}
        style={
          isDropdownOpen
            ? { backgroundColor: colors.secondaryBackground }
            : undefined
        }
      >
        <span
          css={{ minWidth: "4em", fontWeight: "normal", textAlign: "right" }}
        >
          {callStartStr}
        </span>

        <span css={{ pointerEvents: "none" }}>{callTypeIcon}</span>

        <ToolTip
          tooltipContent={
            <div css={tooltipContentCss}>
              {missedCall ? "Missed Call" : incomingCall ? "Incoming Call" : ""}
            </div>
          }
        >
          <span
            css={{
              width: "1.5em",
              color: colors.primaryAccentColor,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {missedCall ? (
              <CallMissedIcon />
            ) : incomingCall ? (
              <CallReceivedIcon css={{ color: colors.secondaryTextColor }} />
            ) : (
              <CallMadeIcon css={{ color: colors.secondaryTextColor }} />
            )}
          </span>
        </ToolTip>

        <span
          css={{
            color: colors.secondaryTextColor,
            flex: "1",
            display: "flex",
            alignItems: "center",
            gap: "0.25em",
          }}
        >
          {phoneType}
          {callTranscript && (
            <IconButton
              css={transcriptButtonCss}
              onClick={(event) => {
                event.stopPropagation();
                setCallTranscriptText(callTranscript);
                setCallTranscriptRemoteSender(
                  call.peer.noNameReturnPhoneNumber()!
                );
                showCallTranscript();
              }}
            >
              <TranscriptIcon />
            </IconButton>
          )}
        </span>

        {missedCall ? (
          <span
            css={{
              color: colors.primaryAccentColor,
              fontSize: "0.85em",
              textAlign: "right",
              lineHeight: "1",
            }}
          >
            Missed
          </span>
        ) : cancelledCall ? (
          <span
            css={{
              color: colors.secondaryTextColor,
              fontSize: "0.85em",
              textAlign: "right",
              lineHeight: "1",
            }}
          >
            Cancelled
          </span>
        ) : (
          <span css={{ fontWeight: "normal" }}>{callDurationStr}</span>
        )}
      </div>

      {isDropdownOpen && (
        <BodyPortal>
          <DeleteDropDownContainer
            ref={dropdownRef}
            style={{
              zIndex: "9999",
              position: "fixed",
              left: `${x}px`,
              top: `${y}px`,
            }}
            css={deleteDropdownContainer}
          >
            <DropDownButton
              css={[
                footerDropdownItem,
                {
                  fontSize: "0.8em",
                },
              ]}
              onClick={(event) => handleDeleteCall(event, key)}
            >
              <DeleteIcon css={{ fontSize: "1em" }} /> Delete
            </DropDownButton>
          </DeleteDropDownContainer>
        </BodyPortal>
      )}
    </div>
  );
}

const tooltipContentCss = css({
  background: colors.tertiaryBackground,
  borderRadius: "4px",
  padding: "0.25em",
  translate: "0 12px",
});
