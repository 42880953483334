import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import { isValidPhoneNumber } from "@/utils";
import cn from "@/utils/cn";
import { contactState, resetContactState } from "@/utils/contacts/contactState";
import { filterContacts } from "@/utils/contacts/contactUtils";
import { createContact } from "@/utils/contacts/index";
import { ease } from "@/utils/ease";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { atoms } from "@/utils/helpers/atoms";
import { ls } from "@/utils/helpers/localstorage";
import { checkPhoneNumberCaps } from "@/utils/helpers/loginAndCaps/capabilities";
import { useToast } from "@/utils/helpers/toastManager";
import { useBlock } from "@/utils/hooks/useBlock";
import { useCall } from "@/utils/hooks/useCall";
import { useContacts } from "@/utils/hooks/useContacts";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { setSelectedConversationId } from "@/utils/messaging/conversation/ConversationState";
import {
  cleanPhoneNumber,
  isSamePhoneNumber,
} from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import ChevronDownIcon from "@heroicons/react/16/solid/ChevronDownIcon";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useClickAway } from "@uidotdev/usehooks";
import { useSetAtom } from "jotai";
import debounce from "lodash/debounce";
import { AnimatePresence, motion } from "motion/react";
import React, {
  ChangeEvent,
  useDeferredValue,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import ContactCard from "./contactListScreen/ContactCard";
import {
  AlphabetEntry,
  CallButtons,
  CallOptions,
  CallOptionsActiveButton,
  CallOptionsButton,
  DialedNumber,
  DropDownButton,
  DropDownContainer,
  NumberButton,
  NumberInput,
  numberInputButtonCss,
  NumberRow,
  NumericEntry,
} from "./keyPad.style";
import { IconButton } from "./shared/Button";
import ContactModal from "./shared/ContactModal";
import ContactPicker from "./shared/ContactPicker";
import { footerDropdown, footerDropdownItem } from "./shared/Dropdown.style";
import InvitePopup from "./shared/InvitePopup";

function sanitizeCallInput(input: string) {
  return input.replaceAll(/[^\d+#*]/g, "");
}

function ContactListDropdown({
  contacts,
  dropdownButtonRef,
  searchQueryLength,
  setNumberToCall,
  closeDropdown,
}: {
  contacts: ReturnType<typeof filterContacts>;
  dropdownButtonRef: React.RefObject<
    HTMLButtonElement | HTMLInputElement | null
  >;
  searchQueryLength: number;
  setNumberToCall: (number: string) => void;
  closeDropdown: () => void;
}) {
  const dropdownRef = useClickAway<HTMLDivElement>((e) => {
    if (dropdownButtonRef.current?.contains(e.target as Node)) {
      return;
    }
    closeDropdown();
  });

  return (
    <div
      ref={dropdownRef}
      className="absolute left-1/2 top-[calc(100%+1em)] z-10 min-w-full -translate-x-1/2 overflow-hidden rounded-lg"
    >
      <motion.ul
        className="h-full max-h-[300px] w-full overflow-auto bg-secondaryBackground px-4"
        css={{
          "::-webkit-scrollbar-track": {
            margin: `0.2rem 0`,
          },
        }}
        initial={{ y: "-100%" }}
        animate={{ y: 0 }}
        exit={{ y: "-100%" }}
        transition={{ ease: ease, duration: 0.35 }}
      >
        {contacts.map(([contact, searchIndices]) => {
          if (!contact.id) return null;

          return (
            <ContactCard
              key={contact.id}
              contact={contact}
              searchIndices={searchIndices}
              searchQueryLength={searchQueryLength}
              onClick={() => {
                // input the full phone number to the keypad
                setNumberToCall(
                  // TODO multiple numbers
                  sanitizeCallInput(contact.getMainPhoneNumber())
                );
                closeDropdown();
              }}
            />
          );
        })}
      </motion.ul>
    </div>
  );
}

function ContactListPreview({
  filteredContacts,
  searchQueryLength,
  setNumberToCall,
}: {
  filteredContacts: ReturnType<typeof filterContacts>;
  searchQueryLength: number;
  setNumberToCall: (number: string) => void;
}) {
  const [showContactList, setShowContactList] = useState(false);
  const toggleContactList = () => setShowContactList((prev) => !prev);
  if (showContactList && filteredContacts.length === 0) {
    setShowContactList(false);
  }

  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div
      className={cn(
        "relative z-10 flex flex-col items-center gap-4 text-secondaryTextColor",
        filteredContacts.length === 0 && "pointer-events-none opacity-0"
      )}
    >
      <p className="max-h-5 min-h-5 min-w-full max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-center text-sm">
        {filteredContacts
          .map(([contact]) => contact.getName())
          .filter(Boolean)
          .join(", ")}
      </p>

      <button
        ref={dropdownButtonRef}
        type="button"
        className="flex w-fit text-nowrap rounded-lg bg-secondaryBackground px-2 py-1"
        onClick={toggleContactList}
      >
        <ChevronDownIcon
          className={cn(
            "w-6 text-primaryAccentColor transition-transform",
            showContactList && "rotate-180"
          )}
        />
        <span className="px-1">{filteredContacts.length} Results</span>
      </button>

      <AnimatePresence>
        {showContactList && (
          <ContactListDropdown
            contacts={filteredContacts}
            dropdownButtonRef={dropdownButtonRef}
            searchQueryLength={searchQueryLength}
            setNumberToCall={setNumberToCall}
            closeDropdown={() => setShowContactList(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

const KeyPad = ({
  onContactBlocked,
}: {
  onContactBlocked: (phoneNumber: string, blocked: boolean) => void;
}) => {
  // State variables
  const [numberToCall, setNumberToCall] = useState("");
  const [showSymbols, setShowSymbols] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetCounter, setResetCounter] = useState(0);
  const [selectedContact, setSelectedContact] = useState<
    WebGwContact | undefined
  >(undefined);
  const [, setAddToContact] = useState<WebGwContact | null>(null);
  const [showContactPicker, setShowContactPicker] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isShowInvitePopup, setShowInvitePopup] = useState(false);

  // Refs
  const callOptionsButtonRef = useRef<HTMLButtonElement>(null);
  const videoCallButtonRef = useRef<HTMLButtonElement>(null);
  const voiceCallButtonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null!);

  // Hooks
  const navigate = useNavigate();
  const contacts = useContacts();
  const contact = contacts?.findWithNumber(numberToCall);
  const { showToast } = useToast();
  // Call-related hooks
  const { callWithAudio, callWithVideo, canAcceptOrMakeCall } = useCall();
  const setDisplayContactId = useSetAtom(atoms.contacts.displayContactId);
  const localUserPhoneNumber = ls.getUser();
  const block = useBlock({
    contact: WebGwContact.fromPhoneNumber(numberToCall),
    onContactBlocked: (blocked) => onContactBlocked(numberToCall, blocked),
    runCheck: false,
  });
  const searchQuery = useDeferredValue(numberToCall);

  // Function to handle making a call
  const handleMakeCall = async (type: "video" | "audio") => {
    const blocked = await block.check();
    if (blocked) {
      block.show("calling");
      return;
    }

    if (type === "video") {
      callWithVideo({ number: numberToCall });
    } else {
      callWithAudio({ number: numberToCall });
    }
  };

  // Function to handle removing a number
  const handleRemoveNumber = debounce((e: React.MouseEvent | KeyboardEvent) => {
    if (
      e instanceof KeyboardEvent &&
      e.key === "Backspace" &&
      numberToCall.length > 0
    ) {
      setNumberToCall(numberToCall.slice(0, -1));
      e.preventDefault();
    } else {
      setNumberToCall(numberToCall.slice(0, -1));
    }
  });

  // Function to sanitize and update the input number
  const changeNumberHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setNumberToCall(sanitizeCallInput(e.target.value));
  };

  // Function to close the contact picker
  const handleCloseContactPicker = () => setShowContactPicker(false);

  // Function to handle selecting contacts
  const handleSelectedContacts = (contacts: WebGwContact[]) => {
    setSelectedContact(contacts[0]);
  };

  // Conditions for allowing a call
  const canMakeCall =
    /[0-9]/.test(numberToCall) &&
    canAcceptOrMakeCall &&
    !isSamePhoneNumber(localUserPhoneNumber, numberToCall);

  // Function to toggle the dropdown
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  // Function to close the dropdown
  const closeDropdown = () => setDropdownOpen(false);

  // Ref for dropdown click-away handling
  const dropdownRef = useClickAway<HTMLDivElement>(closeDropdown);

  // Memoized list of filtered contacts
  const filteredContacts = useMemo(
    () => (searchQuery ? filterContacts(searchQuery, contacts) : []),
    [searchQuery]
  );

  // Utility functions to handle phone number validation and navigatin when selecting to message a contact

  const handleSelectConversation = async () => {
    if (!numberToCall) return;

    try {
      const hasCaps = (await checkPhoneNumberCaps(numberToCall)).caps;

      if (!hasCaps) {
        setShowInvitePopup(true);
        return;
      }

      const createConversation = (contact: WebGwContact | undefined) => {
        const conversation = Conversation.getOrCreate({
          phoneNumber: numberToCall,
          contactToLinkIfCreate: contact || undefined,
        });

        setSelectedConversationId(conversation.conversation.id);
        void navigate(paths.messages);
      };

      if (contact) {
        createConversation(contact);
      } else if (isValidPhoneNumber(numberToCall)) {
        createConversation(WebGwContact.fromPhoneNumber(numberToCall));
      } else {
        setShowInvitePopup(true);
      }
    } catch (error) {
      console.error("Error checking phone number capabilities:", error);
      setShowInvitePopup(true);
    }
  };

  // Function to handle creating a new contact
  const handleOk = async () => {
    setIsModalOpen(false);
    await createContact(contactState, showToast);
    resetContactState();
  };

  // Function to handle canceling modal actions
  const handleCancel = () => {
    resetContactState();
    setIsModalOpen(false);
  };

  // Effect to handle selected contact changes
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (selectedContact) {
      timeoutId = setTimeout(() => {
        const computeNumberToCall = () => {
          // TODO: fix getMainPhoneNumber so only one function is needed
          const mainPhoneNumberWithCaps = cleanPhoneNumber(
            selectedContact.getMainPhoneNumberWithCaps()
          );
          const mainPhoneNumber = cleanPhoneNumber(
            selectedContact.getMainPhoneNumber()
          );

          return (
            mainPhoneNumberWithCaps ||
            mainPhoneNumber ||
            selectedContact.phone?.[0]?.[0] ||
            ""
          );
        };

        const computedNumber = computeNumberToCall();
        setNumberToCall((prev) =>
          prev !== computedNumber ? computedNumber : prev
        );
        setAddToContact((prev) =>
          prev !== selectedContact ? selectedContact : prev
        );
        setSelectedContact(undefined);
      }, 0);
    }

    return () => timeoutId && clearTimeout(timeoutId);
  }, [selectedContact]);

  // Effect to update call button states
  useEffect(() => {
    for (const ref of [
      callOptionsButtonRef,
      videoCallButtonRef,
      voiceCallButtonRef,
    ]) {
      if (ref.current) ref.current.disabled = !canMakeCall;
    }
  }, [canMakeCall]);

  const showModal = () => {
    resetContactState();
    setResetCounter((prev) => prev + 1);
    setIsModalOpen(true);
  };

  return (
    <div
      css={{
        height: "100%",
        width: "60%",
        display: "flex",
        flexDirection: "column",
        gap: "2vh",
      }}
    >
      <NumberInput>
        <DialedNumber
          onChange={changeNumberHandler}
          id="numberInput"
          // If you start by adding the country code when dialing it will detect the country and auto format
          value={numberToCall}
          ref={inputRef}
          type="text"
          autoFocus
        />
        <IconButton
          onClick={handleRemoveNumber}
          css={[
            numberInputButtonCss,
            { ":hover": { backgroundColor: "unset" } },
          ]}
        >
          <BackspaceOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setShowContactPicker(true);
            setDisplayContactId(null);
          }}
          css={[numberInputButtonCss, { color: colors.primaryAccentColor }]}
        >
          <PeopleAltIcon />
        </IconButton>
      </NumberInput>

      <ContactListPreview
        filteredContacts={filteredContacts}
        searchQueryLength={searchQuery.length}
        setNumberToCall={setNumberToCall}
      />

      <div
        css={{
          position: "relative",
          justifyContent: "center",
          display: "flex",
          height: "100%",
          maxHeight: "40em",
        }}
      >
        <Keypad>
          <NumberRow>
            <NumberButton
              onClick={() => setNumberToCall((prev) => prev + "1")}
              disabled={showSymbols}
            >
              <NumericEntry>1</NumericEntry>
              <AlphabetEntry></AlphabetEntry>
            </NumberButton>
            <NumberButton
              onClick={() => setNumberToCall((prev) => prev + "2")}
              disabled={showSymbols}
            >
              <NumericEntry>2</NumericEntry>
              <AlphabetEntry>ABC</AlphabetEntry>
            </NumberButton>
            <NumberButton
              onClick={() => setNumberToCall((prev) => prev + "3")}
              disabled={showSymbols}
            >
              <NumericEntry>3</NumericEntry>
              <AlphabetEntry>DEF</AlphabetEntry>
            </NumberButton>
          </NumberRow>
          <NumberRow>
            <NumberButton
              onClick={() => setNumberToCall((prev) => prev + "4")}
              disabled={showSymbols}
            >
              <NumericEntry>4</NumericEntry>
              <AlphabetEntry>GHI</AlphabetEntry>
            </NumberButton>
            <NumberButton
              onClick={() => setNumberToCall((prev) => prev + "5")}
              disabled={showSymbols}
            >
              <NumericEntry>5</NumericEntry>
              <AlphabetEntry>JKL</AlphabetEntry>
            </NumberButton>
            <NumberButton
              onClick={() => setNumberToCall((prev) => prev + "6")}
              disabled={showSymbols}
            >
              <NumericEntry>6</NumericEntry>
              <AlphabetEntry>MNO</AlphabetEntry>
            </NumberButton>
          </NumberRow>
          {!showSymbols && (
            <NumberRow>
              <NumberButton
                onClick={() => setNumberToCall((prev) => prev + "7")}
              >
                <NumericEntry>7</NumericEntry>
                <AlphabetEntry>PQRS</AlphabetEntry>
              </NumberButton>
              <NumberButton
                onClick={() => setNumberToCall((prev) => prev + "8")}
              >
                <NumericEntry>8</NumericEntry>
                <AlphabetEntry>TUV</AlphabetEntry>
              </NumberButton>
              <NumberButton
                onClick={() => setNumberToCall((prev) => prev + "9")}
              >
                <NumericEntry>9</NumericEntry>
                <AlphabetEntry>WXYZ</AlphabetEntry>
              </NumberButton>
            </NumberRow>
          )}
          {showSymbols && (
            <NumberRow>
              <NumberButton
                onClick={() => setNumberToCall((prev) => prev + "*")}
              >
                <NumericEntry css={{ paddingTop: "1vh" }}>*</NumericEntry>
              </NumberButton>
              <NumberButton
                onClick={() => setNumberToCall((prev) => prev + "+")}
              >
                <NumericEntry>+</NumericEntry>
              </NumberButton>
              <NumberButton
                onClick={() => setNumberToCall((prev) => prev + "#")}
              >
                <NumericEntry>#</NumericEntry>
              </NumberButton>
            </NumberRow>
          )}
          <NumberRow>
            {!showSymbols && (
              <NumberButton
                style={{ justifyContent: "center" }}
                onClick={() => setShowSymbols(true)}
              >
                <NumericEntry>
                  <span
                    style={{
                      fontSize: "2.2vw",
                      position: "relative",
                      bottom: "2px",
                    }}
                  >
                    +
                  </span>
                  <span
                    style={{
                      fontSize: "3.2vw",
                      margin: "0 3px",
                      position: "relative",
                      top: "2px",
                      verticalAlign: "middle",
                    }}
                  >
                    *
                  </span>
                  <span
                    style={{
                      fontSize: "1.7vw",
                      position: "relative",
                      bottom: "2px",
                    }}
                  >
                    #
                  </span>
                </NumericEntry>
                <AlphabetEntry></AlphabetEntry>
              </NumberButton>
            )}
            {showSymbols && (
              <NumberButton
                onClick={() => setShowSymbols(false)}
                style={{ justifyContent: "center" }}
              >
                <NumericEntry>123</NumericEntry>
                <AlphabetEntry></AlphabetEntry>
              </NumberButton>
            )}
            <NumberButton
              style={{ justifyContent: "center" }}
              onClick={() => setNumberToCall((prev) => prev + "0")}
              disabled={showSymbols}
            >
              <NumericEntry>0</NumericEntry>
              <AlphabetEntry></AlphabetEntry>
            </NumberButton>
            <NumberButton disabled={true}>
              <NumericEntry></NumericEntry>
              <AlphabetEntry></AlphabetEntry>
            </NumberButton>
          </NumberRow>
          <NumberRow style={{ alignItems: "center" }}>
            <div css={{ position: "relative", display: "inline-block" }}>
              <NumberButton
                as={isDropdownOpen ? "div" : undefined}
                style={{ justifyContent: "center" }}
                ref={callOptionsButtonRef}
                onClick={toggleDropdown}
                disabled={!canMakeCall}
              >
                {!isDropdownOpen ? (
                  <AddCircleOutlineIcon css={CallOptionsButton} />
                ) : (
                  <AddCircleIcon
                    css={[CallOptionsButton, CallOptionsActiveButton]}
                  />
                )}
                {isDropdownOpen && (
                  // TO DO ADD FUNCTIONALITY TO BUTTONS
                  <DropDownContainer ref={dropdownRef} css={footerDropdown}>
                    {/* //TODO uncomment when we re add ability to add more than one number to a contact */}
                    {/* <DropDownButton
                      css={footerDropdownItem}
                      onClick={showChooseContact}
                    >
                      <PersonOutlineOutlinedIcon /> Add to Existing Contact
                    </DropDownButton> */}
                    <DropDownButton
                      css={footerDropdownItem}
                      onClick={showModal}
                    >
                      <PersonAddOutlinedIcon /> Create New Contact
                    </DropDownButton>
                    {/* TODO uncomment when we are ready to add ability to send message */}
                    <DropDownButton
                      css={footerDropdownItem}
                      onClick={handleSelectConversation}
                    >
                      <MessageOutlinedIcon /> Send Message
                    </DropDownButton>
                  </DropDownContainer>
                )}
              </NumberButton>
            </div>

            <NumberButton
              ref={voiceCallButtonRef}
              onClick={() => handleMakeCall("audio")}
              css={CallOptions}
              disabled={!canMakeCall}
            >
              <PhoneIcon css={CallButtons} />
            </NumberButton>
            <NumberButton
              ref={videoCallButtonRef}
              css={CallOptions}
              onClick={() => handleMakeCall("video")}
              disabled={!canMakeCall}
            >
              <VideocamIcon css={CallButtons} />
            </NumberButton>
          </NumberRow>
        </Keypad>

        <ContactModal
          open={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          numberToCall={numberToCall}
          key={resetCounter}
        />
        {/* // TODO - uncomment when we uncomment "add to existing contact" */}
        {/* <ContactModal
          title="Edit Contact"
          open={isEditModalOpen && !!addToContact && !showContactPicker}
          handleOk={handleEditOk}
          handleCancel={handleCancel}
          numberToCall={numberToCall}
        /> */}

        {showContactPicker && (
          <ContactPicker
            from={"Keypad"}
            preSelectedContacts={selectedContact ? [selectedContact] : []}
            onSelectedContacts={handleSelectedContacts}
            onClose={handleCloseContactPicker}
          />
        )}
        {isShowInvitePopup && (
          <InvitePopup
            togglePopup={() => setShowInvitePopup(false)}
            number={numberToCall}
          />
        )}
      </div>
      {block.modal}
    </div>
  );
};

function Keypad({ children }: { children: React.ReactNode }) {
  const keypadRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const keypad = keypadRef.current;
    const container = keypad?.parentElement;
    if (!container) return;

    const observer = new ResizeObserver((entries) => {
      const containerHeight = entries[0].contentRect.height;
      const keypadScrollHeight = keypad.scrollHeight;

      // Calculate scale based on the ratio of container to keypad height
      const heightScale = Math.min(1, containerHeight / keypadScrollHeight);

      keypad.style.scale = `${heightScale}`;
    });

    observer.observe(container);

    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={keypadRef}
      css={{
        position: "absolute",
        top: "0",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        flexDirection: "column",
        color: colors.primaryTextColor,
        height: "100%",
        width: "90%",
        justifyContent: "space-between",
        transformOrigin: "top left",
      }}
    >
      {children}
    </div>
  );
}

export default KeyPad;
